import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material"
import { useState } from "react"
import { factArray } from '../helpers/Constants'

export const DialogID = ({ socket, requestidOpen, setRequestidOpen }) => {
  const [facts, setFacts] = useState([])

  const handleFactsChange = (e) => {
    const {
      target: { value },
    } = e;
    setFacts(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleRequestidClose = () => {
    setRequestidOpen(false)
    setFacts([])
  }

  const handleRequestidSubmit = () => {
    if (!socket) return
    socket.send(
      JSON.stringify({ "type": "credential-req", "payload": { "credentials": facts } })
    )
    setRequestidOpen(false)
    setFacts([])
  }

  return (
    <Dialog fullWidth open={requestidOpen} onClose={handleRequestidClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {"Request Credentials"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControl size="small" sx={{ mt: 1 }}>
            <InputLabel id="select-facts-label">Select Credentials</InputLabel>
            <Select labelId="select-facts-label" multiple value={facts} onChange={handleFactsChange} input={<OutlinedInput label="Select Facts" />}>
              {factArray.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestidClose}>Cancel</Button>
        <Button disabled={facts.length === 0} onClick={handleRequestidSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
