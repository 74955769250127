import React, { useState, useRef, useEffect } from "react"
import { Theme } from "./Theme"
import { styled } from '@mui/material/styles'
import { alpha, TextField, Box, Menu, MenuItem, Button, CircularProgress, CssBaseline, Paper, Stack, ThemeProvider, Typography } from '@mui/material'
import { DialogID } from "./components/DialogID"
import { DialogDoc } from "./components/DialogDoc"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : Theme.palette.background.default,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}))

export const App = () => {
  const [connected, setConnected] = useState(false)
  const ws = useRef(null)
  const [socket, setSocket] = useState(null)
  const [reconnect, setReconnect] = useState(false)
  const [qrCode, setQrCode] = useState("")
  const [msg, setMsg] = useState('')
  const [requestAnchorEl, setRequestAnchorEl] = useState(null)
  const [sendAnchorEl, setSendAnchorEl] = useState(null)
  const [requestidOpen, setRequestidOpen] = useState(false)
  const [docOpen, setDocOpen] = useState(false)
  const [msgList, setMsgList] = useState([])
  const [data, setData] = useState({})
  const messagesEndRef = useRef(null)

  const connectWebSocket = () => {
    const mainURL = process.env.REACT_APP_URL || "http://localhost:8080"
    const wsURL = mainURL.replace("http", "ws")
    ws.current = new WebSocket(`${wsURL}/app`)
    
    ws.current.onopen = () => {
      console.log("app websocket open")
      setConnected(false)
      setSocket(ws.current)
      ws.current.send(JSON.stringify({"type": "connect-req"}))
    }

    ws.current.onclose = (event) => {
      console.log("WebSocket closed with code:", event.code, "reason:", event.reason)
      setConnected(false)
      if (event.code !== 1000) { // Not a normal closure
        setReconnect(true)
      }
    }
    
    ws.current.onerror = (event) => {
      console.error("WebSocket encountered an error:", event)
      ws.current.close()
    }
    
    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data)
      if (msg["type"] === "connect-req") {
        if (msg["connected"] === true) {
          setConnected(true)
          console.log("connected true")
        } else {
          setQrCode(msg['qr-code'])
          console.log("connected false")
        }
        return
      }
      if (msg["type"] === "force-disconnect") {
        setConnected(false)
        return
      }
      if (msg["type"] === "user-msg") {
        setData(msg["payload"])
        setMsgList(msg["payload"]["messages"])
      }
    }

    ws.current.onping = (e) => {
      ws.current.pong()
    }
  }

  useEffect(() => {
    if (!connected) {
      setReconnect(true)
    }
  }, [connected])

  useEffect(() => {
    if (reconnect) {
      connectWebSocket()
      setReconnect(false)
    } // eslint-disable-next-line
  }, [reconnect])

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    setTimeout(scrollToBottom, 0);
  }, [msgList])

  const handleClick = () => {
    if (socket) {
      socket.close(1000, "Client disconnected")
    } 
    setConnected(false)
  }

  const handleChange = (e) => {
    setMsg(e.target.value)
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (!socket) return
      socket.send(
        JSON.stringify({"type": "message-send", "payload": {"content": msg}})
      ) 
      setMsg('')
    }
  }

  const handleRequestClick = (event) => {
    setRequestAnchorEl(event.currentTarget)
  }
  
  const handleRequestClose = () => {
    setRequestAnchorEl(null)
  }

  const handleRequestidOpen = () => {
    handleRequestClose()
    setRequestidOpen(true)
  }

  const handleSendClick = (event) => {
    setSendAnchorEl(event.currentTarget)
  }
  
  const handleSendClose = () => {
    setSendAnchorEl(null)
  }

  const handleDocOpen = () => {
    handleSendClose()
    setDocOpen(true)
  }

  const capitalizeFirstLowercaseRest = str => {
    return (
      str.charAt(0).toUpperCase() + str.slice(1)
    )
  }

  if (!connected) {
    return(
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Stack sx={{ pt: 10, alignItems: 'center', backgroundColor: (theme) => theme.palette.primary.main, height: '100vh' }} spacing={1}>
          <Typography variant="h4" sx={{ color: Theme.palette.primary.contrastText}}>Self Demo App</Typography>
          {!qrCode && <CircularProgress color="info" />}
          {qrCode && <>
            <Paper sx={{ textAlign: 'center' }}>
              {qrCode && (
                <Typography>
                  <img width="350" src={`data:image/svg+xml;base64,${qrCode}`} alt='QR Code' />
                </Typography>
              )}
            </Paper>
          </>}
        </Stack>
      </ThemeProvider>
    )   
  }

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline/>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100svh' }}>

        <Stack direction='row' sx={{ p: 1, backgroundColor: Theme.palette.primary.main }}>
          <Button variant='text' size='small' sx={{ width: 10, color: Theme.palette.primary.contrastText, '&:hover': {backgroundColor: 'transparent',} }} onClick={handleRequestClick}>request</Button>
          <Menu id="call-menu" anchorEl={requestAnchorEl} keepMounted open={Boolean(requestAnchorEl)} onClose={handleRequestClose}>
            <MenuItem onClick={handleRequestidOpen}>Credentials</MenuItem>
            {requestidOpen && <DialogID  socket={socket} requestidOpen={requestidOpen} setRequestidOpen={setRequestidOpen} />}
          </Menu>

          <Button disabled variant='text' size='small' sx={{ width: 10, color: Theme.palette.primary.contrastText, '&:hover': {backgroundColor: 'transparent',} }} onClick={handleSendClick}>send</Button>
          <Menu id="simple-menu" anchorEl={sendAnchorEl} keepMounted open={Boolean(sendAnchorEl)} onClose={handleSendClose}>
            <MenuItem onClick={handleDocOpen}>Agreement</MenuItem>
            {docOpen && <DialogDoc name={data.name} socket={socket} docOpen={docOpen} setDocOpen={setDocOpen} />}
          </Menu>

          <Typography sx={{ flexGrow: 1 }}></Typography>

          <Button variant='text' size='small' sx={{ width: 'auto', color: Theme.palette.primary.contrastText, '&:hover': {backgroundColor: 'transparent',} }} onClick={handleClick}>disconnect</Button>
        </Stack>

        <Box sx={{ px: 1, flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto', backgroundImage: `linear-gradient(20deg, ` + Theme.palette.background.grad1 + ` 0%, ` + Theme.palette.background.grad2 + ` 100%)` }}>
          <Box sx={{ px: 1, width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {msgList && <>
              {msgList.map(({ Source, Content }) => {
                if (Source === "app") {
                  return <Stack direction="column" alignItems="flex-end" sx={{margin: 1, pt: 1, pb: 1, pl: 8}}>
                    <Item sx={{minWidth: 120, backgroundColor: Theme.palette.background.appMsg}}>
                      {Content}
                    </Item>
                  </Stack>
                } else if (Source === "user") {
                  return <Stack direction="column" alignItems="flex-start" sx={{margin: 1, pt: 1, pb: 1, pr: 8}}>
                    <Item sx={{minWidth: 120}}>
                      {Content}
                    </Item>
                  </Stack>
                } else {
                  return <Stack direction="column" alignItems="center" sx={{margin: 1, pt: 1, pb: 1, px: 4}}>
                    <Item sx={{backgroundColor: Theme.palette.primary.main, color: Theme.palette.primary.contrastText, minWidth: 120 }}>
                      {Content.split(';').map((line, i) => <React.Fragment key={i}>{capitalizeFirstLowercaseRest(line)}<br/></React.Fragment>)}
                    </Item>
                  </Stack>
                }
              })}
            </>}
          <div ref={messagesEndRef} />
          </Box>
        </Box>

        <Stack direction='row' sx={{ px: 2, p: 2, backgroundColor: Theme.palette.primary.main}}>
          <TextField
            sx={{
              flexGrow: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: alpha (Theme.palette.primary.contrastText, 0.7),
                },
                '&:hover fieldset': {
                  borderColor: alpha (Theme.palette.primary.contrastText, 0.7),
                },
                '&.Mui-focused fieldset': {
                  borderColor: alpha (Theme.palette.primary.contrastText, 0.7),
                },
              },
              '& .MuiFormLabel-root': {
                color: alpha (Theme.palette.primary.contrastText, 0.7),
              },
              '& .MuiFormLabel-root.Mui-focused': {
                color: alpha (Theme.palette.primary.contrastText, 0.7),
              },
              '& .MuiInputBase-input': {
                color: Theme.palette.primary.contrastText,
              },
            }}
            label={`Send Message`}
            multiline
            value={msg}
            id='field1'
            variant="outlined"
            size="small"
            onKeyDown={handleEnter}
            onChange={handleChange}
          />
        </Stack>  

      </Box>
    </ThemeProvider>
  )
}