export const factArray = [
  {
    value: 'livenessImageHash',
    label: 'Liveness Check',
  },
  {
    value: 'emailAddress',
    label: 'Email Address',
  },
]

export const operatorArray = [
  {
    value: '==',
    label: 'equal to',
  },
  {
    value: '!=',
    label: 'not equal to',
  },
  {
    value: '>=',
    label: 'greater than or equal to',
  },
  {
    value: '<=',
    label: 'less than or equal to',
  },
  {
    value: '>',
    label: 'greater than',
  },
  {
    value: '<',
    label: 'less than',
  },
]